export default class Norm {
  constructor(strideTimeMean, strideTimeStd, stancePctMean, stancePctStd, swingPctMean, swingPctStd) {
    this.strideTimeMean = strideTimeMean;
    this.strideTimeStd = strideTimeStd;
    this.stancePctMean = stancePctMean;
    this.stancePctStd = stancePctStd;
    this.swingPctMean = swingPctMean;
    this.swingPctStd = swingPctStd;
  }

  toDict() {
    return {
      stride_time_mean: this.strideTimeMean,
      stride_time_std: this.strideTimeStd,
      stance_pct_mean: this.stancePctMean,
      stance_pct_std: this.stancePctStd,
      swing_pct_mean: this.swingPctMean,
      swing_pct_std: this.swingPctStd,
    };
  }

  toString() {
    // Use the toDict method for a structured string representation
    return `Norm: ${JSON.stringify(this.toDict(), null, 2)}`;
  }
}