import Papa from "papaparse"; // CSV parsing library
import Norm from "./Norm";

export default class NormSource {
  constructor(sex) {
    if (sex === "M") {
      this.filename = "/dist/assets/resources/norms/Male.csv";
    } else if (sex === "F") {
      this.filename = "/dist/assets/resources/norms/Female.csv";
    } else {
      // there are no norms for this subject
      this.filename = null
    }
    this.data = {};
    this.ageGroups = [];
  }

  async loadCSV() {
    return new Promise((resolve, reject) => {
      if (this.filename) {
        Papa.parse(this.filename, {
          download: true,
          header: true,
          complete: (results) => {
            const { data, meta } = results;
            if (data.length === 0) {
              resolve(null);
            } else {
              this.ageGroups = Object.keys(data[0]).slice(1); // Age groups are column headers (except the first)
              this.data = data.reduce((acc, row) => {
                const label = row["Age"];
                acc[label] = Object.values(row).slice(1).map((v) => parseFloat(v));
                return acc;
              }, {});
              resolve({ data, meta });
            }
          },
          error: (err) => resolve(null),
        });
      } else {
        resolve(null);
      }
    });
  }

  getAgeGroup(age) {
    for (const ageGroup of this.ageGroups) {
      try {
        const [lower, upper] = ageGroup.split("-").map((v) => parseInt(v, 10));
        if (lower <= age && age <= upper) {
          return ageGroup;
        }
      } catch (err) {
        continue; // Skip invalid columns
      }
    }
    return null;
  }

  getNorm(age) {
    const ageGroup = this.getAgeGroup(age);

    if (!ageGroup) {
      return null;
    }

    const ageGroupIndex = this.ageGroups.indexOf(ageGroup);

    if (ageGroupIndex === -1) {
      return null;
    }
    // Retrieve the norm values
    const strideTimeMean = 1000 * this.data["Stride Time Mean"][ageGroupIndex];
    const strideTimeStd = 1000 * this.data["Stride Time Std"][ageGroupIndex];
    const stancePctMean = this.data["Stance % Mean"][ageGroupIndex];
    const stancePctStd = this.data["Stance % Std"][ageGroupIndex];
    const swingPctMean = this.data["Swing % Mean"][ageGroupIndex];
    const swingPctStd = this.data["Swing % Std"][ageGroupIndex];

    // Create and return a Norm object
    return new Norm(
      strideTimeMean,
      strideTimeStd,
      stancePctMean,
      stancePctStd,
      swingPctMean,
      swingPctStd
    );
  }
}
