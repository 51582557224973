import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function PainPRO({ labels, data, trialNames, timeAxis, minDate, maxDate, paddedMinDateStr, paddedMaxDateStr, ...props }) {
  // Filter out NaN values from data and corresponding labels
  const filteredData = useMemo(() => data.filter(value => !isNaN(value)), [data]);
  const filteredLabels = useMemo(() => labels.filter((_, index) => !isNaN(data[index])), [labels, data]);
  const filteredTrialNames = useMemo(() => trialNames.filter((_, index) => !isNaN(data[index])), [trialNames, data])
  const filteredDataByDate = useMemo(() => {
    return data.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`); // Convert label to Date object
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());

      // Normalize minDate and maxDate to midnight if not already
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return (
        labelDate >= minDateNoTime &&
        labelDate <= maxDateNoTime &&
        !isNaN(value)
      );
    });
  }, [data, labels, minDate, maxDate]);

const formattedDelta = useMemo(() => {
  const delta = filteredData.length >= 2 ? filteredData[filteredData.length - 1] - filteredData[filteredData.length - 2] : null;
  return (delta !== null && !Number.isNaN(delta)) ? `${delta > 0 ? '+' : ''}${delta.toFixed(2)}` : 'N/A';
}, [filteredData]);

  const vasRef = useRef<Chart<'line'>>(null);
  const [averageText, setAverageText] = useState(0);

  useEffect(() => {
    if (filteredDataByDate.length > 0) {
      const average = filteredDataByDate.reduce((sum, value) => sum + value, 0) / filteredDataByDate.length;
      setAverageText(average.toFixed(2));
    } else {
      setAverageText(0); // Default to 0 if no data
    }
  }, [filteredDataByDate]); // Run effect when filteredDataByDate changes
  
  const placeholder_vasData = useMemo<ChartData<'line'>>(
    () => ({
      labels: filteredLabels.length > 0 ? filteredLabels : [],
      datasets: [
        {
          type: "line",
          label: "VAS Pain Scores",
          lineTension: 0.0,
          backgroundColor: "rgba(0,97,242,0.1)",
          borderColor: "rgba(0, 97, 242, 1)",
          pointRadius: 3,
          // fill: true,
          pointBackgroundColor: "rgba(0, 97, 242, 1)",
          pointBorderColor: "rgba(0, 97, 242, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
          pointHoverBorderColor: "rgba(0, 97, 242, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: filteredData.length > 0 ? filteredData : [],
        },
      ],
    }),
    [filteredLabels, filteredData]
  );
  const placeholder_vasOptions = useMemo<ChartOptions<'line'>>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: paddedMinDateStr, // Set min date
          max: paddedMaxDateStr, // Set max date
          grid: axesOptions.disabledGrid,
          ticks: {
            callback: function (value, index, values) {
              let toReturn = value;
              // if (timeAxis === "millisecond") {
              //   let splitVal = value.split(" ");
              //   let index = splitVal[0].lastIndexOf(".");
              //   toReturn = splitVal[0].substring(0, index) + " " + splitVal[1];
              // }
              return toReturn;
            },
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          // max: 100,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value, index, values) {
              return value;
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        legend: false,
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              let trialName = filteredTrialNames.length > 0 ? filteredTrialNames[tooltipItems[0].dataIndex] : null;
              if (!trialName) {
                    trialName = "Unknown"; // Fallback if name is not available
                }
                return trialName;
            },
            label: function (context) {
                let splitIndex = context.label.lastIndexOf(",");
                let labelValue = context.label;
        
                if (timeAxis === "hour") {
                    labelValue = context.label;
                } else if (timeAxis === "day") {
                    labelValue = context.label.slice(0, splitIndex);
                } else if (timeAxis === "month") {
                    labelValue = context.label.slice(0, 4);
                } else if (timeAxis === "millisecond") {
                    labelValue = context.label;
                }
                return `${labelValue}: ${context.parsed.y}`;
            },
        },
        },
        datalabels: {
          align: 'end', // You can use 'start', 'end', 'right', 'left', 'top', or 'bottom'
          anchor: 'end', // Anchors the label to the 'end' of the data point
          offset: 4, // Number of pixels to offset the label from the anchor point, adjust as needed
          formatter: (value, context) => {
            const minDate = new Date(paddedMinDateStr)
            const maxDate = new Date(paddedMaxDateStr)
            const differenceInTime = maxDate.getTime() - minDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
            // if (Math.round(differenceInDays) <= 17) { // 17 because 7 days w/ 5 days padding on either side
            //   return value; // Format the datalabel text
            // } else {
              return ""
            // }
          },
          // You can add more styling options here as needed
        },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            // x: { min: "2021-11-05T23:39:30", max: "2021-11-08T11:39:30" },
            y: { min: 0, max: 600 },
          },
          zoom: {
            wheel: {
              enabled: zoomOptions.zoomEnabled,
            },
            pinch: {
              enabled: zoomOptions.zoomEnabled,
            },
            mode: "x",
          },
        },
      },
    }),
    [timeAxis, filteredTrialNames, paddedMinDateStr, paddedMaxDateStr]
  );

  useEffect(() => {
    setVasData(placeholder_vasData);
    setVasOptions(placeholder_vasOptions);
  }, [placeholder_vasData, placeholder_vasOptions]);

  const [vasData, setVasData] = useState<ChartData<'line'>>(placeholder_vasData);
  const [vasOptions, setVasOptions] = useState<ChartOptions<'line'>>(placeholder_vasOptions);

  return (
    <div className="card mb-4">
      <div className="vasGraph">
      <div className="d-flex card-header align-items-center justify-content-between">
    VAS Pain Scores
    <div className="d-flex align-items-center">
        {formattedDelta !== "N/A" && <span style={{fontSize: '0.9rem'}} className="mr-3">Since Last Collection: {formattedDelta}</span>}
        {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
            <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                    if (vasRef.current) {
                        vasRef.current.resetZoom();
                    }
                }}
            >
                Reset
            </button>
        ) : (
            ""
        )}
    </div>
</div>
        <div className="card-body">
        <div className="chart-pie" style={{ position: 'relative' }}>
    <Line ref={vasRef} data={vasData} height={50} width={100} options={vasOptions}></Line>
</div>
        </div>
        <div className="card-footer small d-flex justify-content-end">
          <span>
            Average ({minDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })} -{" "}
            {maxDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}): {averageText}
          </span>
        </div>      </div>
    </div>
  );
}
