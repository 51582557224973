import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function StanceTimeLineGraph({
  labels,
  data_l,
  data_r,
  trialNames,
  lastUpdatedTime,
  timeAxis,
  minDate,
  maxDate,
  paddedMinDateStr,
  paddedMaxDateStr,
  norm,
  ...props
}) {
  // Filter out NaN values from data and corresponding labels
  const filteredData_l = useMemo(() => data_l.map((value) => (isNaN(value) ? null : value)), [data_l]);
  const filteredData_r = useMemo(() => data_r.map((value) => (isNaN(value) ? null : value)), [data_r]);

  const filteredDataByDate_l = useMemo(() => {
    return data_l.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`); // Convert label to Date object
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());

      // Normalize minDate and maxDate to midnight if not already
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return (
        labelDate >= minDateNoTime &&
        labelDate <= maxDateNoTime &&
        !isNaN(value)
      );
    });
  }, [data_l, labels, minDate, maxDate]);

  const filteredDataByDate_r = useMemo(() => {
    return data_r.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`); // Convert label to Date object
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());

      // Normalize minDate and maxDate to midnight if not already
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return (
        labelDate >= minDateNoTime &&
        labelDate <= maxDateNoTime &&
        !isNaN(value)
      );
    });
  }, [data_r, labels, minDate, maxDate]);

  const formattedDelta_l = useMemo(() => {
    // Filter out null values first
    const validData = filteredData_l.filter((value) => value !== null && !isNaN(value));

    if (validData.length >= 2) {
      const lastValue = validData[validData.length - 1];
      const previousValue = validData[validData.length - 2];
      const delta = lastValue - previousValue;
      return `${delta > 0 ? "+" : ""}${delta.toFixed(2)} ms`;
    }
    return "N/A";
  }, [filteredData_l]);

  const formattedDelta_r = useMemo(() => {
    // Filter out null values first
    const validData = filteredData_r.filter((value) => value !== null && !isNaN(value));

    if (validData.length >= 2) {
      const lastValue = validData[validData.length - 1];
      const previousValue = validData[validData.length - 2];
      const delta = lastValue - previousValue;
      return `${delta > 0 ? "+" : ""}${delta.toFixed(2)} ms`;
    }
    return "N/A";
  }, [filteredData_r]);

  // Norm bounds

  const stanceTimeMean = norm ? (norm.strideTimeMean * norm.stancePctMean) / 100 : 0;
  const stanceTimeStd = norm ? Math.sqrt(
    Math.pow(norm.stancePctStd / 100, 2) * Math.pow(norm.strideTimeMean, 2) + Math.pow(norm.strideTimeStd, 2) * Math.pow(norm.stancePctMean / 100, 2)
  ) : 0;

  const lowerBound = norm ? stanceTimeMean - 1.96 * stanceTimeStd : 0;
  const upperBound = norm ? stanceTimeMean + 1.96 * stanceTimeStd : 0;

  const stanceTimeRef = useRef<Chart<"line">>(null);
  const [averageText_l, setAverageText_l] = useState(0);
  const [averageText_r, setAverageText_r] = useState(0);

  useEffect(() => {
    if (filteredDataByDate_l.length > 0) {
      const average = filteredDataByDate_l.reduce((sum, value) => sum + value, 0) / filteredDataByDate_l.length;
      setAverageText_l(average.toFixed(2));
    } else {
      setAverageText_l(0); // Default to 0 if no data
    }
  }, [filteredDataByDate_l]); // Run effect when filteredDataByDate changes

  useEffect(() => {
    if (filteredDataByDate_r.length > 0) {
      const average = filteredDataByDate_r.reduce((sum, value) => sum + value, 0) / filteredDataByDate_r.length;
      setAverageText_r(average.toFixed(2));
    } else {
      setAverageText_r(0); // Default to 0 if no data
    }
  }, [filteredDataByDate_r]); // Run effect when filteredDataByDate changes

    const normRangeDataset = useMemo(
      () => ({
        type: "line",
        label: norm ? "Normal Range" : "hidden", // Shown in legend
        data: [
          { x: paddedMinDateStr, y: lowerBound },
          { x: paddedMaxDateStr, y: lowerBound },
        ],
        borderColor: "rgba(173, 216, 230, 0)",
        backgroundColor: "rgba(173, 216, 230, 0.3)",
        fill: "+1",
        pointRadius: 0,
        pointHoverRadius: 0, // No hover effect
        order: 0,
      }),
      [paddedMinDateStr, paddedMaxDateStr, lowerBound, norm]
    );

      const normRangeUpperDataset = useMemo(
        () => ({
          type: "line",
          label: "hidden", // Hide from legend
          data: [
            { x: paddedMinDateStr, y: upperBound },
            { x: paddedMaxDateStr, y: upperBound },
          ],
          borderColor: "rgba(173, 216, 230, 0)",
          backgroundColor: "rgba(173, 216, 230, 0.3)",
          fill: false, // End fill from previous
          pointRadius: 0,
          pointHoverRadius: 0, // No hover effect
          order: 0,
        }),
        [paddedMinDateStr, paddedMaxDateStr, upperBound]
      );

  const mainLineDataset_l = useMemo(
    () => ({
      type: "line",
      data: filteredData_l.map((val, idx) => ({
        x: labels[idx],
        y: val,
      })),
      label: "Left Stance Times",
      lineTension: 0.0,
      backgroundColor: "rgba(0,97,242,0.1)",
      borderColor: "rgba(0, 97, 242, 1)",
      pointRadius: 3,
      pointBackgroundColor: "rgba(0, 97, 242, 1)",
      pointBorderColor: "rgba(0, 97, 242, 1)",
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
      pointHoverBorderColor: "rgba(0, 97, 242, 1)",
      pointHitRadius: 10,
      pointBorderWidth: 2,
      spanGaps: true,
      order: 1,
    }),
    [labels, filteredData_l]
  );

  const mainLineDataset_r = useMemo(
    () => ({
      type: "line",
      data: filteredData_r.map((val, idx) => ({
        x: labels[idx],
        y: val,
      })),
      label: "Right Stance Times",
      lineTension: 0.0, // No smoothing
      borderColor: "rgba(100, 100, 100, 1)", // Dark gray line color
      borderDash: [10, 5], // Dashed line pattern (10px dash, 5px gap)
      backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
      pointStyle: "rect", // Square points
      pointRadius: 5, // Size of square points
      pointBackgroundColor: "rgba(50, 50, 50, 1)", // Dark gray point color
      pointBorderColor: "rgba(50, 50, 50, 1)", // Dark gray border
      pointHoverRadius: 6, // Slightly larger on hover
      pointHoverBackgroundColor: "rgba(50, 50, 50, 1)", // Dark gray hover color
      pointHoverBorderColor: "rgba(50, 50, 50, 1)", // Dark gray hover border
      pointBorderWidth: 2, // Thickness of point borders
      spanGaps: true,
      order: 1,
    }),
    [labels, filteredData_r]
  );

    const placeholder_stanceTimeData = useMemo<ChartData<"line">>(
      () => ({
        labels: [], // We'll rely on object-based data for x,y
        datasets: [normRangeDataset, normRangeUpperDataset, mainLineDataset_l, mainLineDataset_r],
      }),
      [normRangeDataset, normRangeUpperDataset, mainLineDataset_l, mainLineDataset_r]
    );

  const placeholder_stanceTimeOptions = useMemo<ChartOptions<"line">>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: paddedMinDateStr, // Set min date
          max: paddedMaxDateStr, // Set max date
          grid: axesOptions.disabledGrid,
          ticks: {
            callback: function (value, index, values) {
              let toReturn = value;
              // if (timeAxis === "millisecond") {
              //   let splitVal = value.split(" ");
              //   let index = splitVal[0].lastIndexOf(".");
              //   toReturn = splitVal[0].substring(0, index) + " " + splitVal[1];
              // }
              return toReturn;
            },
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          // max: 100,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value, index, values) {
              return value + " ms";
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        datalabels: {
          display: false, // No point labels
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
            // Step 1: Filter out datasets labeled "hidden"
            filter: function (legendItem) {
              return !legendItem.text.includes("hidden");
            },
        
            // Step 2: Customize the styles for "Normal Range" vs. everything else
            generateLabels: function (chart) {
              const defaultLabels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
              const datasets = chart.data.datasets;
        
              // Map each dataset to a custom legend label object
              return defaultLabels.map((labelObj, i) => {
                const dataset = datasets[i];
                if (!dataset) return labelObj; // Safety check
        
                // If dataset.label === "Normal Range", we show a box with background
                if (dataset.label === "Normal Range") {
                  labelObj.pointStyle = "rect";  // or "rectRounded"
                  labelObj.fillStyle = dataset.backgroundColor || "rgba(173, 216, 230, 0.3)";
                  labelObj.strokeStyle = "rgba(0, 0, 0, 0)"; // No border
                } else {
                  // For all other datasets (non-'hidden'), use a line style
                  labelObj.pointStyle = "line";
                  labelObj.strokeStyle = dataset.borderColor || "rgba(0, 97, 242, 1)";
                  labelObj.fillStyle = dataset.backgroundColor || "rgba(0, 97, 242, 0)"; // Transparent fill
                }
        
                return labelObj;
              });
            },
          },
        },
        tooltip: {
          filter: function (tooltipItem) {
            // datasetIndex 2 is main line. 0 is Norm Range, 1 is hidden upper.
            return tooltipItem.datasetIndex === 2 || tooltipItem.datasetIndex === 3;
          },
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,

          callbacks: {
            title: function (tooltipItems) {
              let trialName = trialNames.length > 0 ? trialNames[tooltipItems[0].dataIndex] : null;
              if (!trialName) {
                trialName = "Unknown"; // Fallback if name is not available
              }
              return trialName;
            },
            label: function (context) {
              // This is only called for datasetIndex=2 now
              let labelValue = context.label;
              const splitIndex = labelValue.lastIndexOf(",");
              if (timeAxis === "hour") {
                labelValue = context.label;
              } else if (timeAxis === "day") {
                labelValue = context.label.slice(0, splitIndex);
              } else if (timeAxis === "month") {
                labelValue = context.label.slice(0, 4);
              } else if (timeAxis === "millisecond") {
                labelValue = context.label;
              }
              return `${labelValue}: ${context.parsed.y} ms`;
            },
          },
        },
      },
    }),
    [timeAxis, trialNames, paddedMinDateStr, paddedMaxDateStr]
  );

  useEffect(() => {
    setStanceTimeData(placeholder_stanceTimeData);
    setStanceTimeOptions(placeholder_stanceTimeOptions);
  }, [placeholder_stanceTimeData, placeholder_stanceTimeOptions]);

  const [stanceTimeData, setStanceTimeData] = useState<ChartData<"line">>(placeholder_stanceTimeData);
  const [stanceTimeOptions, setStanceTimeOptions] = useState<ChartOptions<"line">>(placeholder_stanceTimeOptions);

  return (
    <div className="card mb-4">
      <div className="stanceTimeGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Stance Times
          <div className="d-flex align-items-center">
            {formattedDelta_l !== "N/A" && (
              <span style={{ fontSize: "0.9rem" }} className="mr-3">
                Since Last Collection: {formattedDelta_l} (L) {formattedDelta_r} (R)
              </span>
            )}
            {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
              <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                  if (stanceTimeRef.current) {
                    stanceTimeRef.current.resetZoom();
                  }
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-pie" style={{ position: "relative" }}>
            <Line ref={stanceTimeRef} data={stanceTimeData} height={50} width={100} options={stanceTimeOptions}></Line>
          </div>
        </div>
        <div className="card-footer small d-flex justify-content-between">
          <span>
            Normal Range:{" "}
            {norm
              ? `${lowerBound.toFixed(2)} - ${upperBound.toFixed(2)} ms`
              : "N/A"}
          </span>
          <span>
            Average ({minDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })} -{" "}
            {maxDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}): {averageText_l} ms (L) {averageText_r} ms (R)
          </span>
        </div>
      </div>
    </div>
  );
}
